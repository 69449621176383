.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: rgb(18, 18, 18, 0.7);
    /* Adjust the last value (0.9) to your desired transparency */
    color: #fff;
    padding: 1rem;
    position: fixed;
    /* Add this line */
    top: 0;
    /* Add this line */
    left: 0;
    /* Add this line */
    right: 0;
    /* Add this line */
    z-index: 1000;
    /* Add this line to make sure the navbar stays on top of other elements */
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.8);
}

.navbar ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navbar li {
    margin: 0 0.5rem;
}

.navbar a {
    text-decoration: none;
    color: #ffffff;
}

.navbar a:hover {
    text-decoration: underline;
}

.account-info {
    display: flex;
    align-items: center;
}

.shortened-account {
    margin-right: 10px;
    font-family: 'Montserrat', sans - serif;

}

.native-token-balance {
    margin-right: 30px;
    font-family: 'Montserrat', sans-serif;

}