.landing-page .hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 0 5%;
    background: linear-gradient(to right, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 100%), url('../../../public/MOONS.webp') no-repeat center center;

    background-size: cover;
    margin-bottom: 60px;
}

.landing-page .moon-animation img {
    max-width: 50%;
    height: auto;
    
}


.landing-page .title h1 {
    color: #fff;
}

.landing-page .roadmap-container {
    padding: 50px 5%;
    color: #fff;
}

.landing-page .roadmap-entry p {
    max-width: 70%; /* Adjust the max-width as needed */
    margin: 0 auto; /* This centers the paragraph if the width is less than 100% */
    padding: 0 20px; /* Optional: adds padding inside the paragraph for better readability */
    text-align: center; /* Optional: Justifies the text for better readability */
  }

.landing-page .roadmap-entry.visible {
    opacity: 1;
    transform: translateY(0);
}




.roadmap-container {
    padding: 50px 5%;
    text-align: center; /* Center the text */
}

.roadmap-entry {
    opacity: 0;
    transform: translateY(70px);
    transition: opacity 1.5s, transform 1.9s;
    margin-bottom: 150px; /* Increase space between sections */
    padding: 20px; /* Add some padding inside the sections */
    border-radius: 10px; /* Optional: Rounded corners for sections */
    background: rgba(255, 255, 255, 0.1); /* Optional: Semi-transparent background */
    backdrop-filter: blur(100px); /* Optional: Blur effect for the background */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
}

.roadmap-entry.visible {
    opacity: 1;
    transform: translateY(0);
}

.roadmap-entry h2, .roadmap-entry p {
    margin: 20px 0; /* Add more space around headings and paragraphs */
    text-align: center; /* Ensure text is centered */
}

.roadmap-tech {
    margin-bottom: 150px; /* Increase space between sections */
    padding: 20px; /* Add some padding inside the sections */
    border-radius: 10px; /* Optional: Rounded corners for sections */
    background: rgba(255, 255, 255, 0.1); /* Optional: Semi-transparent background */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
}



.roadmap-tech h2, .roadmap-entry p {
    margin: 20px 0; /* Add more space around headings and paragraphs */
    text-align: center; /* Ensure text is centered */
}

/* Optional: Add a class for decorative graphics */
.roadmap-graphic {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 350px; /* Adjust the height for your graphics */
    width: 100%; /* Ensure the graphic takes full width of its container */
    margin-top: 20px; /* Space between graphic and text */
    margin-bottom: 40px;
}

.roadmap-techgraphic {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 250px; /* Adjust the height for your graphics */
    width: 100%; /* Ensure the graphic takes full width of its container */
    margin-top: 20px; /* Space between graphic and text */
    margin-bottom: 40px;
}

.roadmap-graphic::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 1) 100%);
    pointer-events: none; /* This ensures clicks go through the pseudo-element */
}

.roadmap-techgraphic::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 1) 100%);
    pointer-events: none; /* This ensures clicks go through the pseudo-element */
}


.title-button-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically (if needed) */
    margin-right: 15%; /* Adjust as needed */
}

@media (max-width: 768px) {
    .title-button-container {
        margin-right: 0%; /* No margin on the right on mobile devices */
    }
    .landing-page .moon-animation img {
        display:none;
        
    }
}


  .top-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1; /* Ensure it's above other content */
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 3s ease-in ;
  }